@import url('@nimbus-ds/tokens/dist/css/variables.css');

:root {
  --background: var(--color-sys-light-neutral-surface);
  --background-white: #ffffff;
}

/* The admin currently doesn't support dark mode. When it does, uncomment the following lines to provide dark mode support. */
/* @media (prefers-color-scheme: dark) {
  :root {
    --background: var(--color-sys-dark-neutral-surface);
  }
} */

* {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: initial;
}

html,
body {
  max-width: 100vw;
  height: 100vh;
}

button[class*='primary'] {
  background-color: #009dff !important;
  color: white !important;
}

button[class*='neutral'] {
  background-color: rgb(255, 255, 255) !important;
  color: #009dff !important;
  border-color: #009dff !important;
}

button a,
button a:visited,
button a:hover,
button a:target {
  text-decoration: none;
  color: white;
}

button[disabled] {
  opacity: 0.5;
}

input[type='checkbox'][disabled] {
  opacity: 0.5;
}

body {
  background-color: var(--background-white);
  background: url(../public/siigo_background.jpg);
  background-repeat: no-repeat;
  background-size: cover;
}

#nimbus-tooltip-floating p {
  max-width: 300px;
  font-size: 16px !important;
  line-height: 1.2rem;
}

#setup-register-link {
  font-size: 18px;
}

td {
  word-break: break-all;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: light;
  }
}
